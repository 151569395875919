<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img ref="previewEl" rounded :src="optionsLocal.avatar" height="80" />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>
      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
          @click="fileinputclick" disabled hidden>
          {{ $t("Upload") }}
        </b-button>
        <!--/ upload button -->
        <!-- reset -->
        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" size="sm" class="mb-75 mr-75"
          @click="resetImage" disabled hidden>
          {{ $t("Reset") }}
        </b-button>
        <!--/ reset -->
        <b-card-text disabled hidden>{{ $t("Allowed JPG, GIF or PNG. Max size of 800kB")
          }}</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->
    <!-- form -->
    <b-form class="mt-2" ref="editProfileGeneralForm">
      <b-form-file ref="refInputEl" id="fileinput" v-model="profileFile" accept=".jpg, .png, .gif" :hidden="true" plain
        @input="inputImageRenderer" disabled hidden />
      <b-row>
        <!-- <b-col sm="6">
          <b-form-group
            label="Username"
            label-for="account-username"
          >
            <b-form-input
              v-model="optionsLocal.username"
              placeholder="Username"
              name="username"
            />
          </b-form-group>
        </b-col> -->
        <!-- <b-col sm="12">
          <b-form-group label-for="account-company">
            <b-form-input
              v-model="optionsLocal.company"
              name="company"
              placeholder="Company name"
            /> 
            <h3>{{ optionsLocal.company }}</h3>
          </b-form-group>
        </b-col> -->
        <b-col sm="6">
          <b-form-group :label="$t('Full Name')" label-for="account-name">
            <b-form-input v-model="optionsLocal.name" name="name" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('E-Mail')" label-for="account-e-mail">
            <b-form-input v-model="optionsLocal.email" name="email" disabled :formatter="emailFormatter" type="email"
              @blur="emailValidate($event)" @focus="
                  $event.target.classList.remove('is-invalid');
                  $event.target.placeholder = $t('E-mail');
                " required />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('Phone Number')" label-for="account-phone">
            <b-form-input name="phone" v-model="optionsLocal.phone" disabled :formatter="phoneFormatter" required
              minlength="10" maxlength="10" @blur="
                  if ($event.target.value.length < 10) {
                    $event.target.classList.add('is-invalid');
                    $event.target.placeholder = $t('Minimum 10 digits');
                    $event.target.value = '';
                  }
                " @focus="
                  $event.target.classList.remove('is-invalid');
                  $event.target.placeholder = $t('Phone Number');
                " />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('Referrer')" label-for="account-referrer">
            <b-form-input name="referrer" v-model="optionsLocal.referrer.name" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="12" v-if="!optionsLocal.identification" class="form-group">
          <b-row style="align-items: center">
            <b-col sm="6">
              <!-- <b-form-group
                :label="$t('Identification Number')"
                label-for="account-identification"
              > -->
              <b-row style="
                    padding-left: 1rem;
                    padding-right: 1rem;
                    justify-content: space-between;
                  ">
                <label>{{ $t("Identification Number") }}</label>
                <b-badge variant="primary" pill style="cursor: pointer"
                  :title="$t('Identification Number is necessary to send Invoices')"> ? </b-badge>
              </b-row>
              <b-form-input name="identification" v-model="optionsLocal.identificationNumber" required
                :placeholder="$t('Identification Number')" @blur="
                    if ($event.target.value.length < 10) {
                      $event.target.classList.add('is-invalid');
                      $event.target.placeholder = $t('Minimum 10 digits');
                      $event.target.value = '';
                    }
                  " @focus="
                    $event.target.classList.remove('is-invalid');
                    $event.target.placeholder = $t('Identification Number');
                  " />
              <!-- </b-form-group> -->
            </b-col>
            <b-col sm="6">
              <b-row style="
                    padding-left: 1rem;
                    padding-right: 1rem;
                    justify-content: space-between;
                  " disabled hidden>
                <label>{{ $t("Identification") }}</label>
                <b-badge variant="primary" pill style="cursor: pointer" :title="
                      $t(
                        'Your Name and Identification Number must be clearly visible in the picture'
                      )
                    "> ? </b-badge>
              </b-row>
              <!-- <b-form-group
                :label="$t('Identification')"
                label-for="account-identification"
              > -->
              <b-form-file id="identificationFileInput" v-model="identificationFile" accept=".jpg, .png, .pdf, .jpeg,"
                ref="identificationFileInput" disabled hidden>
              </b-form-file>
              <!-- </b-form-group
            > -->
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="6" v-else>
          <h3>{{ $t("Identification Number") }}</h3>
          <p>{{ optionsLocal.identification }}</p>
        </b-col>
        <b-col sm="12" v-if="!optionsLocal.approved" class="form-group" disabled hidden>
          <b-row style="align-items: center">
            <b-col sm="6" v-if="!optionsLocal.awaitingApproval">
              <b-badge variant="danger" pill>
                {{ $t("Not Approved") }}
              </b-badge>
              <span class="ml-05">
                {{ $t("You Will Get One Program For Free On Approval") }}
              </span>
            </b-col>
            <b-col sm="6" v-if="!optionsLocal.awaitingApproval" disabled hidden>
              <!-- add file input for degree -->
              <!-- <b-form-group :label="$t('Degree')" label-for="account-degree"> -->
              <b-row style="
                    padding-left: 1rem;
                    padding-right: 1rem;
                    justify-content: space-between;
                  ">
                <label>{{ $t("Degree") }}</label>
                <b-badge variant="primary" pill style="cursor: pointer" :title="
                      $t(
                        'We need to verify your degree to verify you are qualified to use our platform'
                      )
                    " disabled hidden> ? </b-badge>
              </b-row>
              <b-form-file disabled hidden id="degreeFileInput" v-model="degreeFile" accept=".jpg, .png, .pdf, .jpeg,"
                ref="degreeFileInput">
              </b-form-file>
              <!-- </b-form-group> -->
            </b-col>
            <b-col sm="12" v-else disabled hidden>
              <b-badge variant="warning" pill>
                {{ $t("Awaiting Approval") }}
              </b-badge>
              <span class="ml-5">
                {{ $t("You Will Get One Program For Free On Approval") }}
              </span>
            </b-col>
          </b-row>
          <!-- <b-badge v-else class="btn btn-success">
            {{ $t("Approved") }}
          </b-badge> -->
        </b-col>
        <b-col sm="6" v-else disabled hidden>
          <b-badge variant="success" disabled hidden>
            {{ $t("Approved") }}
          </b-badge>
          <!-- <h3>{{ $t("Degree") }}</h3> -->
          <!-- <p>{{ optionsLocal.degree }}</p> -->
        </b-col>
        <!-- alert -->
        <b-col sm="6" disabled hidden>
          <b-button variant="primary" class="mr-1" pill @click="shareReferralCode" disabled hidden>
            <FeatherIcon icon="ShareIcon" size="14" />
          </b-button>
          <span>{{ $t("Your Referral Code To Share") }}</span>
        </b-col>
        <b-col sm="6" disabled hidden>
          <span>{{ optionsLocal._id }}</span>
          <b-button variant="primary" class="ml-1" pill @click="copyReferralCode" disabled hidden>
            <FeatherIcon icon="CopyIcon" size="14" />
          </b-button>
        </b-col>
        <b-col cols="12" class="mt-75" v-if="!optionsLocal.emailApproved" disabled hidden>
          <b-alert show variant="warning" class="mb-50">
            <h4 class="alert-heading">
              {{ $t("Your email is not confirmed. Please check your inbox.") }}
            </h4>
            <div class="alert-body">
              <b-link class="alert-link">
                {{ $t("Resend confirmation") }}
              </b-link>
            </div>
          </b-alert>
        </b-col>
        <!--/ alert -->
        <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1" @click="updateProfile"
            :disabled="optionsLocal.awaitingApproval" :title="
                optionsLocal.awaitingApproval
                  ? $t('Cannot Modify While Waiting Approval')
                  : $t('Save Changes')
              " disabled hidden>
            {{ $t("Save Changes") }}
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            {{ $t("Reset") }}
          </b-button> -->
        </b-col>
      </b-row>
      <b-button v-for="document in documents" :href="document" target="_blank" variant="primary" class="ml-1"
        :key="document.name">
        <FeatherIcon icon="FileIcon" />
      </b-button>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BBadge,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";

const {
  axiosRoutes: { user: path },
} = require("/G_CONFIG.js");
export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  // props: {
  //   generalData: {
  //     type: Object,
  //     default: () => {},
  //   },
  // },
  props: {
    id: {
      type: String,
      required: true,
    },
    documents: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      profileFile: null,
      userData: {},
      degreeFile: null,
      identificationFile: null,
      // optionsLocal: null /* JSON.parse(JSON.stringify(this.generalData)), */,
    };
  },
  methods: {
    nullFunc() {},
    phoneFormatter(phone) {
      // dont allow non numeric characters and remove beginning 0 limit to 10 digits (for now)
      // phone = phone.replace(/[^\d]/g, "").replace(/^0/, "").slice(0, 10);

      // // make the format look like +xxx (xxx) xxx xx xx xx or (xxx) xxx xx xx depending on the length
      // if (phone.length > 10) {
      //   // phone = phone.replace(
      //   //   /^(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})$/,
      //   //   "+$1 ($2) $3 $4 $5"
      //   // );
      //   phone = phone.replace(/^(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})$/, "+$1 $2 $3 $4 $5");
      // } else {
      //   phone = phone.replace(/^(\d{3})(\d{3})(\d{2})(\d{2})$/, "($1) $2 $3 $4");
      // }
      return phone;
    },
    emailFormatter(email) {
      //enforce a valid email is entered
      // email = email.replace(/[^a-zA-Z0-9@.]/g, "");
      return email;
    },
    emailValidate(event) {
      // let email = event.target.value;
      // if (!email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
      //   event.target.value = "";
      //   event.target.placeholder = this.$t("Invalid Email");
      //   event.target.classList.add("is-invalid");
      // }
    },
    fileinputclick() {
      // #fileinput click
      // document.getElementById("fileinput").click();
    },
    shareReferralCode() {
      // if (navigator.share) {
      //   navigator
      //     .share({
      //       title: "Diko",
      //       text: "Diko Referral Code",
      //       url: this.optionsLocal._id,
      //     })
      //     .then(() => console.log("Successful share"))
      //     .catch((error) => console.log("Error sharing", error));
      // } else {
      //   console.log("Share not supported");
      // }
    },
    copyReferralCode() {
      // const el = document.createElement("textarea");
      // el.value = this.optionsLocal._id;
      // document.body.appendChild(el);
      // el.select();
      // document.execCommand("copy");
      // document.body.removeChild(el);
    },
    resetImage() {
      // this.profileFile = null;
      // this.avatar = axios.defaults.baseURL + "/images/dikologo.png";
    },
    popupAccountApproval() {
      // this.$bvModal.show("modal-approve-account");
    },
    updateProfile() {
      // let formData = new FormData();
      // formData.append("avatarfile", this.$refs.refInputEl.files[0]);
      // formData.append("identificationFile", this.$refs.identificationFileInput.files[0]);
      // formData.append("degreeFile", this.$refs.degreeFileInput.files[0]);
      // formData.append(
      //   "phone",
      //   this.optionsLocal.phone
      //     .replace(/\s/g, "")
      //     .replace("+", "")
      //     .replace("(", "")
      //     .replace(")", "")
      // );
      // formData.append("identification", this.optionsLocal.identificationNumber);
      // formData.append("referrer", this.optionsLocal.referrer);
      // formData.append("name", this.optionsLocal.name);
      // formData.append("email", this.optionsLocal.email);
      // axios
      //   .patch(`${path.edit}/${this.optionsLocal._id}`, formData, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   })
      //   .then(({ data }) => {
      //     // console.log(res);
      //     localStorage.setItem("userAvatar", axios.defaults.baseURL + data.avatar);
      //     window.location.reload();
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
  },
  setup(props, refs) {
    // console.log("refs", refs);
    // console.log("props", props);
    const refInputEl = ref(null);
    const previewEl = ref(null);
    const fileItself = ref(null);
    const optionsLocal = ref(null);
    // let userData = {};
    const previewElChange = (image) => {
      // refs.previewEl.src = image;
      // props.generalData.avatar = image;
      refs.refs.previewEl.src = image;
      // console.log(previewEl);
    };
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewElChange);
    console.log(props.documents);
    axios.get(path.getOne + "/" + props.id).then(({ data }) => {
      // console.log(res.data);
      // console.log(data);
      // refs.refs.optionsLocal = res.data;
      // userData = data;
      // this.userData.avatar = axios.defaults.baseURL + data.avatar;
      optionsLocal.value = data;
      optionsLocal.value.avatar = axios.defaults.baseURL + data.avatar;
      optionsLocal.value.identificationNumber = data.identification;
      console.log("refferrer");
      console.log(data.referrer);
      if (data.referrer?.name) {
        optionsLocal.value.referrer.name = data.referrer.name;
      } else {
        optionsLocal.value.referrer = { name: " YOK " };
      }
      console.log(optionsLocal.referrer);
    });
    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
      fileItself,
      optionsLocal,
      // userData,
    };
  },
};
</script>
<style scoped>
.is-invalid {
  border-color: #dc3545;
}
</style>
